const UTM_KEYS = [
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_term",
  "utm_content",
] as const;

// 2. Derive the UTMFields type from those keys
type UTMFields = {
  [K in (typeof UTM_KEYS)[number]]?: string;
};

export const init = (): void => {
  storeUTMData();
  cleanURL();
};

const storeUTMData = (): void => {
  const params = new URLSearchParams(window.location.search);
  const utmFields: UTMFields = {};

  UTM_KEYS.forEach((param) => {
    const value = params.get(param);
    if (value) {
      utmFields[param as keyof UTMFields] = value;
    }
  });

  // Store the UTM fields in a single cookie for 30 days
  if (Object.keys(utmFields).length > 0) {
    setCookie("utm_data", JSON.stringify(utmFields), 30);
  }
};

const cleanURL = (): void => {
  const url = new URL(window.location.href);
  UTM_KEYS.forEach((param) => url.searchParams.delete(param));
  window.history.replaceState({}, document.title, url.pathname + url.search);
};

const setCookie = (name: string, value: string, days: number): void => {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);

  const domain = window.location.hostname.split(".").slice(-2).join(".");
  document.cookie = `${name}=${encodeURIComponent(value)}; expires=${date.toUTCString()}; path=/; domain=${domain}`;
};
