import { Controller } from "@hotwired/stimulus";

/**
 * Controls a "select all" checkbox that toggles multiple target checkboxes
 * Usage:
 * <input type="checkbox" data-controller="select-all" data-select-all-target-value="checkbox-group">
 */
export default class SelectAllController extends Controller {
  static values = {
    target: String, // CSS class that identifies target checkboxes
  };

  declare readonly targetValue: string;

  connect(): void {
    this.element.addEventListener(
      "change",
      this.toggleTargetCheckboxes.bind(this),
    );
  }

  /**
   * Toggles all target checkboxes to match the state of the controller checkbox
   */
  toggleTargetCheckboxes(event: Event): void {
    const controllerCheckbox = event.target as HTMLInputElement;
    const targetCheckboxes = Array.from(
      document.getElementsByClassName(this.targetValue),
    ) as HTMLInputElement[];

    targetCheckboxes.forEach((checkbox) => {
      checkbox.checked = controllerCheckbox.checked;
    });
  }

  disconnect(): void {
    this.element.removeEventListener(
      "change",
      this.toggleTargetCheckboxes.bind(this),
    );
  }
}
